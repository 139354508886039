
import { Vue, Component} from "vue-property-decorator";
import { capitalizeFirst } from "@/utils";
import { IInstructor, IUser } from "@/schemas/IUser";
import { ISequence } from "@/schemas/ISequence";
import SequenceForm from "@/components/admin/entityForm/SequenceForm.vue";
import TipGroupForm from "@/components/admin/entityForm/TipGroupForm.vue";
import ActivityForm from "@/components/admin/entityForm/ActivityForm.vue";
import {mapGetters, mapState} from "vuex";

@Component({
  computed: {
    ...mapState("user", ["users"]),
    ...mapGetters("user", ["isAdministrator"]),
  },
})
export default class registerAdminPage extends Vue {

  toValidateUsers: {
    email: string;
    username: string;
  }[] = [];

  data(): any {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Nom d'utilisateur",
          align: 'start',
          sortable: false,
          value: 'username',
        },
        { text: 'Nom', value: 'nom' },
        { text: 'Prenom', value: 'prenom' },
        { text: 'EAFC', value: 'eafc' },
        { text: 'Email', value: 'email' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      users: [],
      editedIndex: -1,

    }
  }

  created(){

    this.$store.dispatch(
      "user/fetchWaitingValidation",
      (instructors: IInstructor[]) => (this.setUsers(instructors))
    );
}

setUsers(instructors){
  for(var i=0; i< instructors.length; i++){
    var toParse = instructors[i].waiting_instruct_info
    var wii = JSON.parse(toParse);
    instructors[i].nom = wii.nom
    instructors[i].prenom = wii.prenom
    instructors[i].eafc = wii.eafc
  }
  this.users=instructors
}


validate(){
  let waitingValidation=[]
  for(var i=0; i< this.toValidateUsers.length; i++){
    waitingValidation.push({"username":this.toValidateUsers[i].username, "email":this.toValidateUsers[i].email});
  }
  this.$store.dispatch("user/validateWaitingUser",{
    users: waitingValidation,
    next: () => {
      this.$store.dispatch(
        "user/fetchWaitingValidation",
        (instructors: IInstructor[]) => (this.setUsers(instructors))
      );
    },}
  );
  // ENVOYER LE MAIL DE VALIDATION
}

  onCheckChange(
    isChecked: boolean,
    user: { email: string; username: string }
  ): void {
    if (isChecked) {
      if (!this.toValidateUsers.find((u) => u.email === user.email)) {
        this.toValidateUsers.push(user);
      }
    } else {
      this.toValidateUsers = this.toValidateUsers.filter(
        (u) => u.email != user.email
      );
    }
    console.log(this.toValidateUsers)
  }


  get allChecked(): boolean {
    return this.users.every((waitingUser) =>
      this.toValidateUsers.includes(waitingUser)
    );
  }

  set allChecked(areAllChecked: boolean) {
    if (areAllChecked) {
      this.users.forEach((waitingUser) => {
        if (
          !this.toValidateUsers.find(
            (user) =>
              user.username == waitingUser.username
          )
        ) {
          this.toValidateUsers.push(waitingUser);
        }
      });
      return;
    }
    this.toValidateUsers = this.toValidateUsers.filter(
      (u) =>
        !this.users.find(
          (user) => user.username == u.username
        )
    );
  }

  removeSetting(userToDelete): void {
    if (this.users) {
      let elementToRemove = this.users.find((user) =>user.username == userToDelete.username);
      if (elementToRemove) {
        this.$store.dispatch("user/deleteWaitingUser",{
          users: userToDelete,
          next: () => {
            //TODO : envoyer le mail de refus
            this.users = this.users.filter((u) =>elementToRemove.username !== u.username);
            },}
          );
        }
      }
      this.dialog=false
    }
}

//
//

